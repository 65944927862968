<template>
    <v-row v-if="template !== null">
        <v-col cols="12">
            <v-card>
                <v-card-title>{{ template.alias }}</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense :label="$t('erp.lang_TemplateName')" v-model="template.alias" outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-alert text color="info" class="text-center mb-0">{{$t("generic.lang_preferableLogoImageDims")}}</v-alert>
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="primary" @click="showLogoUploadDialog = true"><v-icon style="margin-right: 10px;">backup</v-icon>{{$t('generic.lang_uploadLogo')}}</v-btn>
                            <v-btn :loading="deleteLogoLoading" :disabled="deleteLogoLoading" @click="deleteLogo" v-if="template.logo !== null" color="error"><v-icon style="margin-right: 10px;">delete</v-icon>{{$t('generic.lang_removeLogo')}}</v-btn>
                        </v-col>

                        <v-col v-if="template.logo === null || template.logo.length === 0" cols="9" class="justify-center">
                            <v-alert text color="info" class="text-center"><b>Info:</b> Sie haben noch kein Logo hochgeladen.<br>Es wird das 3POS Kassenlogo verwendet.</v-alert>
                            <v-img height="100" contain src="../../../../assets/images/3pos.svg"></v-img>
                        </v-col>

                        <v-col v-else cols="9" class="justify-center">
                            <v-img height="100" contain :src="logoUrl"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card :disabled="loading" :loading="loading">
              <v-card-title>
                {{$t('settings.lang_slideshow_images')}}
              </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-alert text color="info" class="text-center mb-0">{{$t("generic.lang_preferableSlideshowImagesDims")}}</v-alert>
                            </v-col>
                            <v-col cols="9">
                                <v-btn color="primary" @click="showImageUploadDialog = true">{{$t('generic.lang_uploadImage')}}</v-btn>

                                <v-container fluid>
                                    <v-row>
                                        <v-col
                                                v-for="image in template.slideshow_images"
                                                :key="image"
                                                class="d-flex child-flex"
                                                cols="4"
                                        >
                                            <TemplateSlideshowImage @imageDelete="imageDeleted" :template="template" :image="image"></TemplateSlideshowImage>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :loading="loading" :disabled="loading" @click="saveTemplate">{{ $t('generic.lang_save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <TemplateLogoUploadDialog :show-dialog="showLogoUploadDialog" :template="template" @imageUploaded="logoUploaded" @closeDialog="showLogoUploadDialog = false"></TemplateLogoUploadDialog>
        <TemplateImageUploadDialog :show-dialog="showImageUploadDialog" :template="template" @imageUploaded="imageUploaded" @closeDialog="showImageUploadDialog = false"></TemplateImageUploadDialog>
    </v-row>
</template>

<script>
    import {ENDPOINTS} from "@/config";
    import TemplateSlideshowImage from "./TemplateSlideshowImage";
    import TemplateImageUploadDialog from "./TemplateImageUploadDialog";
    import TemplateLogoUploadDialog from "./TemplateLogoUploadDialog";
    import {mapState} from "vuex";

    export default {
        name: "ImagesTemplate",

        components: {
            TemplateLogoUploadDialog,
            TemplateImageUploadDialog,
            TemplateSlideshowImage
        },

        data() {
            return {
                template: null,
                loading: false,
                deleteLogoLoading: false,
                showLogoUploadDialog: false,
                showImageUploadDialog: false
            }
        },

        mounted() {
            this.getTemplate();
        },

        computed: {
            ...mapState([
                'api'
            ]),
            logoUrl() {
                if(this.template === null)
                    return "";

                return "https://img.3pos.de/unsafe/billsys_"+this.api.auth.posID+"/original/"+this.template.logo+".png";
            }
        },

        methods: {
            getTemplate() {
                this.loading = true;

                this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.GET, {
                  slideID: this.$route.params.id
                }).then((res) => {
                    if(res.data.success) {
                        this.template = res.data.template;
                    }
                }).finally(()=>{
                  this.loading = false;
                })
            },
            saveTemplate() {
                this.loading = true;

                this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.UPDATE, {
                    templateID: this.template.uuid,
                    alias: this.template.alias
                }).then((res) => {
                    if(res.data.success) {
                      this.$router.push('/kiosk/images-template');
                    }
                }).finally(()=>{
                  this.loading = false;
                })
            },
            logoUploaded() {

                this.showLogoUploadDialog = false;
                this.getTemplate();
            },
            imageUploaded() {

                this.showImageUploadDialog = false;
                this.getTemplate();
            },
            imageDeleted() {

                this.getTemplate();
            },
            deleteLogo() {
                this.deleteLogoLoading = true;

                this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.LOGO.DELETE, {
                  slideID: this.template.uuid
                }).then((res) => {
                    if(res.data.success) {

                        this.getTemplate();
                    }
                }).catch(() => {

                }).finally(()=>{
                  this.deleteLogoLoading = false;
                })
            }
        }
    }
</script>