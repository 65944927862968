<template>
    <v-card flat tile>
        <v-img :src="imageUrl" aspect-ratio="1" class="grey lighten-2">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
            </template>
        </v-img>

        <v-card-actions>
            <v-btn @click="deleteImage" :loading="deleteLoading" :disabled="deleteLoading" color="error" text>
                <v-icon>delete</v-icon> {{$t('generic.lang_delete')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {mapState} from "vuex";
    import {ENDPOINTS} from "@/config";

    export default {
        name: "TemplateSlideshowImage",

        components: {
        },

        props: {
            image: String,
            template: Object
        },

        computed: {
            ...mapState([
                'api'
            ]),
            imageUrl() {
                return "https://img.3pos.de/unsafe/250x250/billsys_"+this.api.auth.posID+"/original/"+this.image;
            }
        },

        data() {
            return {
                deleteLoading: false
            }
        },

        methods: {
            deleteImage() {
                this.deleteLoading = true;

                this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.SLIDERIMAGE.DELETE, {
                    slideID: this.template.uuid,
                    imageUID: this.image
                }).then((res) => {
                    if(res.data.success) {
                        this.$emit("imageDelete");
                    }

                    this.deleteLoading = false;
                }).catch(() => {
                    this.deleteLoading = false;
                });
            }
        }
    }
</script>