<template>
  <div>
    <page-title show-previous-button :heading="$t('kiosk.lang_kioskImagesTemplates')" :subheading="$t('kiosk.lang_kioskImagesTemplates')" :icon=icon></page-title>

    <div class="app-main__inner">
      <ImagesTemplate/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import ImagesTemplate from "@/components/kiosk/settings/imagesupload/ImagesTemplate";

export default {
  name: "KioskImageTemplate",

  components: {
    PageTitle,
    ImagesTemplate
  },

  data: () => ({
    icon: 'pe-7s-monitor icon-gradient bg-tempting-azure'
  })
}
</script>